import store from '../store'
import router from '../router'
import axios from 'axios'

async function testConnection() {
  return new Promise((resolve, reject) => {
    const protocol = process.env.VUE_APP_BS_PROTOCOL
    const hostname = process.env.VUE_APP_BS_HOSTNAME
    const port = process.env.VUE_APP_BS_PORT

    const token = store.getters.getToken
    const body = {}

    if (token) {
      const options = {
        auth: {
          username: 'token',
          password: token,
        },
      }

      axios
        .post(`${protocol}://${hostname}:${port}/api/test-token`, body, options)
        .then(() => {
          resolve(true)
        })
        .catch(() => {
          store.commit('updateToken', undefined)
          store.commit('updateLoginDenialMessage', 'Die Sitzung ist abgelaufen')
          reject('Die Sitzung ist abgelaufen')
        })
    } else {
      const username = store.getters.getUsername
      const password = store.getters.getPassword

      if (!username || !password || username == '' || password == '') {
        resolve(false)
        return
      }

      const options = {
        auth: {
          username,
          password,
        },
      }

      axios
        .post(`${protocol}://${hostname}:${port}/api/login`, body, options)
        .then((response) => {
          const token = response['data']['token']
          store.commit('updateUsername', undefined)
          store.commit('updatePassword', undefined)
          store.commit('updateToken', token)
          resolve(true)
        })
        .catch((err) => {
          if (err.message === 'Network Error') {
            reject('Server konnten nicht erreicht werden')
          } else if (err.message.startsWith('Request failed')) {
            reject('Falsche Username/Passwort Kombination')
          } else {
            resolve(false)
          }
        })
    }
  })
}

async function sendRequest(endpoint, method, body) {
  return new Promise((resolve, reject) => {
    const token = store.getters.getToken
    const protocol = process.env.VUE_APP_BS_PROTOCOL
    const hostname = process.env.VUE_APP_BS_HOSTNAME
    const port = process.env.VUE_APP_BS_PORT

    if (token) {
      axios({
        method: method,
        url: `${protocol}://${hostname}:${port}/api/${endpoint}`,
        data: body,
        auth: {
          username: 'token',
          password: token,
        },
      })
        .then((response) => {
          resolve(response['data'])
        })
        .catch(reject)
    }
  })
}

async function sendMailRequest(endpoint, method, body) {
  return new Promise((resolve, reject) => {
    const token = store.getters.getToken
    const protocol = process.env.VUE_APP_BS_PROTOCOL
    const hostname = process.env.VUE_APP_BS_HOSTNAME
    const port = process.env.VUE_APP_BS_PORT

    if (token) {
      axios({
        method: method,
        url: `${protocol}://${hostname}:${port}/mail/${endpoint}`,
        data: body,
        auth: {
          username: 'token',
          password: token,
        },
      })
        .then((response) => {
          resolve(response['data'])
        })
        .catch(reject)
    }
  })
}

async function getBlob(endpoint, method, body) {
  return new Promise((resolve, reject) => {
    const token = store.getters.getToken
    const protocol = process.env.VUE_APP_BS_PROTOCOL
    const hostname = process.env.VUE_APP_BS_HOSTNAME
    const port = process.env.VUE_APP_BS_PORT

    if (token) {
      axios({
        method: method,
        url: `${protocol}://${hostname}:${port}/api/${endpoint}`,
        data: body,
        auth: {
          username: 'token',
          password: token,
        },
        responseType: 'arraybuffer',
      })
        .then((response) => {
          resolve(response['data'])
        })
        .catch(reject)
    }
  })
}

async function getOrder(id) {
  return new Promise((resolve, reject) => {
    sendRequest('order/' + id, 'GET')
      .then(resolve)
      .catch(reject)
  })
}

async function getOrders() {
  return new Promise((resolve, reject) => {
    sendRequest('orders', 'GET').then(resolve).catch(reject)
  })
}

async function setNewInvoiceId(id) {
  return new Promise((resolve, reject) => {
    sendRequest('order/' + id + '/update-invoice', 'POST')
      .then(resolve)
      .catch(reject)
  })
}

async function getOrdersFromLastMonth() {
  return new Promise((resolve, reject) => {
    sendRequest('orders/last-month', 'GET').then(resolve).catch(reject)
  })
}

async function getOrderBundle(orderId) {
  return new Promise((resolve, reject) => {
    getBlob(`bundle/${orderId}`, 'GET').then(resolve).catch(reject)
  })
}

async function getMultiBundle(orderIds) {
  return new Promise((resolve, reject) => {
    getBlob(`bundle-multi`, 'POST', {
      orderIds: orderIds,
    })
      .then(resolve)
      .catch(reject)
  })
}

export async function modifyOrderStatus(orderId, status) {
  return new Promise((resolve, reject) => {
    sendRequest(`order/${orderId}/status`, 'PUT', { status })
      .then(resolve)
      .catch(reject)
  })
}

export async function modifyOrderStatusMulti(orderIds, status) {
  return new Promise((resolve, reject) => {
    sendRequest(`order/status-multi`, 'PUT', {
      orderIds: orderIds,
      status: status,
    })
      .then(resolve)
      .catch(reject)
  })
}

async function getPostAddress(id) {
  return new Promise((resolve, reject) => {
    sendRequest('post-address/' + id, 'GET')
      .then(resolve)
      .catch(reject)
  })
}

async function getPostAddresses() {
  return new Promise((resolve, reject) => {
    sendRequest('post-addresses', 'GET').then(resolve).catch(reject)
  })
}

async function getAddress(id) {
  return new Promise((resolve, reject) => {
    sendRequest('address/' + id, 'GET')
      .then(resolve)
      .catch(reject)
  })
}

async function getAddresses() {
  return new Promise((resolve, reject) => {
    sendRequest('addresses', 'GET').then(resolve).catch(reject)
  })
}

async function getCustomer(id) {
  return new Promise((resolve, reject) => {
    sendRequest('customer/' + id, 'GET')
      .then(resolve)
      .catch(reject)
  })
}

async function getCustomers() {
  return new Promise((resolve, reject) => {
    sendRequest('customers', 'GET').then(resolve).catch(reject)
  })
}

async function getUsers() {
  return new Promise((resolve, reject) => {
    sendRequest('users', 'GET').then(resolve).catch(reject)
  })
}

async function createInvoice(orderId) {
  return new Promise((resolve, reject) => {
    sendRequest(`bill/generate/${orderId}`, 'POST').then(resolve).catch(reject)
  })
}

async function getInvoice(invoiceId) {
  return new Promise((resolve, reject) => {
    sendRequest(`bill/${invoiceId}`, 'GET').then(resolve).catch(reject)
  })
}

async function getInvoicePDF(invoiceId) {
  return new Promise((resolve, reject) => {
    getBlob(`bill/${invoiceId}/pdf`, 'GET').then(resolve).catch(reject)
  })
}

async function getImage(orderId, slot) {
  return new Promise((resolve, reject) => {
    getBlob(`foto/${orderId}/${slot}`, 'GET').then(resolve).catch(reject)
  })
}

async function getThumbnailAsBase64(orderId, slot) {
  return new Promise((resolve, reject) => {
    sendRequest(`thumbnail/${orderId}/${slot}/base64`, 'GET')
      .then(resolve)
      .catch(reject)
  })
}

async function getConfig() {
  return new Promise((resolve, reject) => {
    sendRequest(`config`, 'GET').then(resolve).catch(reject)
  })
}

async function updateConfig(key, value) {
  return new Promise((resolve, reject) => {
    sendRequest(`config`, 'PUT', { key, value }).then(resolve).catch(reject)
  })
}

async function getVoucherCodes() {
  return new Promise((resolve, reject) => {
    sendRequest(`voucher`, 'GET').then(resolve).catch(reject)
  })
}

async function createVoucher(voucher) {
  return new Promise((resolve, reject) => {
    sendRequest(`voucher`, 'POST', voucher).then(resolve).catch(reject)
  })
}

async function editVoucher(voucher) {
  return new Promise((resolve, reject) => {
    sendRequest(`voucher`, 'PUT', voucher).then(resolve).catch(reject)
  })
}

async function editAuthorizedCountries(user) {
  return new Promise((resolve, reject) => {
    sendRequest(`user/${user.id}/edit`, 'PUT', { user: user })
      .then(resolve)
      .catch(reject)
  })
}

async function deleteVoucher(key) {
  return new Promise((resolve, reject) => {
    sendRequest(`voucher`, 'DELETE', { key }).then(resolve).catch(reject)
  })
}

async function deleteUsers(userIds) {
  return new Promise((resolve, reject) => {
    sendRequest(`users/delete`, 'DELETE', { userIds: userIds })
      .then(resolve)
      .catch(reject)
  })
}

async function getPriceViaOrder(orderId) {
  return new Promise((resolve, reject) => {
    sendRequest(`price/${orderId}`, 'GET').then(resolve).catch(reject)
  })
}

async function getMailLinks() {
  return new Promise((resolve, reject) => {
    sendRequest(`mail-link`, 'GET').then(resolve).catch(reject)
  })
}

async function getMailTemplate(templateId) {
  return new Promise((resolve, reject) => {
    sendMailRequest(`template/${templateId}`, 'GET').then(resolve).catch(reject)
  })
}

async function getMailTemplates() {
  return new Promise((resolve, reject) => {
    sendMailRequest(`templates`, 'GET').then(resolve).catch(reject)
  })
}

async function createMailTemplate(template) {
  return new Promise((resolve, reject) => {
    sendMailRequest(`template`, 'POST', template).then(resolve).catch(reject)
  })
}

async function updateMailTemplate(templateId, changes) {
  return new Promise((resolve, reject) => {
    sendMailRequest(`template/${templateId}`, 'PUT', changes)
      .then(resolve)
      .catch(reject)
  })
}

async function deleteMailTemplate(templateId) {
  return new Promise((resolve, reject) => {
    sendMailRequest(`template/${templateId}`, 'DELETE')
      .then(resolve)
      .catch(reject)
  })
}

async function setMailAutoSend(templateId, auto) {
  return new Promise((resolve, reject) => {
    sendMailRequest(`template/${templateId}`, 'PUT', { auto })
      .then(resolve)
      .catch(reject)
  })
}

async function linkMailLink(key, templateId) {
  return new Promise((resolve, reject) => {
    sendRequest(`mail-link/${key}`, 'POST', { template: templateId })
      .then(resolve)
      .catch(reject)
  })
}

async function setAdmin(userId, flag) {
  return new Promise((resolve, reject) => {
    sendRequest(`user/${userId}/admin`, 'PUT', { admin: flag })
      .then(resolve)
      .catch(reject)
  })
}

async function isAdmin() {
  return new Promise((resolve, reject) => {
    sendRequest(`user/is-admin`, 'GET').then(resolve).catch(reject)
  })
}

async function unlinkMailLink(key) {
  return new Promise((resolve, reject) => {
    sendRequest(`mail-link/${key}`, 'DELETE').then(resolve).catch(reject)
  })
}

async function sendMailViaTemplate(tempalteId, target, placeholders) {
  return new Promise((resolve, reject) => {
    sendMailRequest(`template/send/${tempalteId}`, 'POST', {
      target_mail: target,
      placeholders,
    })
      .then(resolve)
      .catch(reject)
  })
}

async function getMails() {
  return new Promise((resolve, reject) => {
    sendMailRequest(`mails`, 'GET').then(resolve).catch(reject)
  })
}

async function getCountries() {
  return new Promise((resolve, reject) => {
    sendRequest(`countries`, 'GET').then(resolve).catch(reject)
  })
}

async function exportCustomers() {
  return new Promise((resolve, reject) => {
    sendRequest(`customers/export-customers`, 'POST')
      .then(resolve)
      .catch(reject)
  })
}

async function updateCustomer(customer) {
  return new Promise((resolve, reject) => {
    sendRequest(`update-customer`, 'POST', {
      customer: customer,
    })
      .then(resolve)
      .catch(reject)
  })
}

async function updateAddress(address) {
  return new Promise((resolve, reject) => {
    sendRequest(`update-address`, 'POST', {
      address: address,
    })
      .then(resolve)
      .catch(reject)
  })
}

async function updateOrderPhoneNumbers(order) {
  return new Promise((resolve, reject) => {
    sendRequest(`update-order`, 'POST', {
      order: order,
    })
      .then(resolve)
      .catch(reject)
  })
}

async function createWebserviceUser(user) {
  return new Promise((resolve, reject) => {
    sendRequest(`create-user`, 'POST', {
      user: user,
    })
      .then(resolve)
      .catch(reject)
  })
}

async function createPostLabel(orderId, shipper, recipient) {
  return new Promise((resolve, reject) => {
    sendRequest(`label/generate/${orderId}`, 'POST', {
      shipper: shipper,
      recipient: recipient,
    })
      .then(resolve)
      .catch(reject)
  })
}

async function setPostLabelCreated(orderId) {
  return new Promise((resolve, reject) => {
    sendRequest(`order/update-postlabel/${orderId}`, 'POST')
      .then(resolve)
      .catch(reject)
  })
}

async function getGlobalVariable(key) {
  return new Promise((resolve, reject) => {
    sendRequest(`global`, 'POST', { key: key }).then(resolve).catch(reject)
  })
}

async function getPostLabelPDF(orderId) {
  return new Promise((resolve, reject) => {
    getBlob(`label/${orderId}/pdf`, 'GET').then(resolve).catch(reject)
  })
}

async function logout() {
  store.commit('updateUsername', undefined)
  store.commit('updatePassword', undefined)
  store.commit('updateToken', undefined)
  await router.push('/login')
}

export default {
  testConnection,
  getOrder,
  getOrders,
  setNewInvoiceId,
  getOrdersFromLastMonth,
  getOrderBundle,
  getMultiBundle,
  modifyOrderStatus,
  modifyOrderStatusMulti,
  getCustomer,
  getCustomers,
  getUsers,
  getAddress,
  getAddresses,
  setAdmin,
  isAdmin,
  getPostAddress,
  getPostAddresses,
  createInvoice,
  getInvoice,
  getInvoicePDF,
  deleteUsers,
  getThumbnailAsBase64,
  getImage,
  getConfig,
  updateConfig,
  getVoucherCodes,
  createVoucher,
  editVoucher,
  editAuthorizedCountries,
  deleteVoucher,
  getPriceViaOrder,
  getMailLinks,
  getMailTemplate,
  getMailTemplates,
  createMailTemplate,
  updateMailTemplate,
  deleteMailTemplate,
  setMailAutoSend,
  linkMailLink,
  unlinkMailLink,
  sendMailViaTemplate,
  getMails,
  getCountries,
  exportCustomers,
  updateCustomer,
  updateAddress,
  updateOrderPhoneNumbers,
  createWebserviceUser,
  createPostLabel,
  setPostLabelCreated,
  getPostLabelPDF,
  getGlobalVariable,
  logout,
}
